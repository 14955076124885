@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: Poppins, system-ui, sans-serif;
	}
}

.class-input {
	-webkit-min-logical-width: calc(100% - 16px);
}

.doctor-div:hover .profession {
	color: white
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: white;
	border-radius: 5px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: #555;
} */


.react-calendar__tile--now {
	background: #ff7640;
}
